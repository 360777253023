import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GradientText } from "../components/Common"
import Head from "../components/head"
import { BREAKPOINTS } from "../constants"
import Layout from "../layouts/layout"

const LargeGradientText = styled(GradientText)`
  font-size: 20rem;
  font-family: "Mosk Ultra Bold", sans-serif;
  line-height: 100%;
  letter-spacing: 0.024em;
  text-align: center;
  margin-top: 0;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    font-size: 8rem;
  }
`
const CustomH1 = styled.h1`
  text-align: center;
`
const CustomP = styled.p`
  text-align: center;
`

function NotFound() {
  return (
    <Layout>
      <Head title="Page not found" />
      <LargeGradientText>404</LargeGradientText>
      <CustomH1>Page not found</CustomH1>
      <CustomP>
        <Link to="/">Head home</Link>
      </CustomP>
    </Layout>
  )
}

export default NotFound
